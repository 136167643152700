import React from "react";
import "./Hero.css";

export default function Hero() {
  return (
    <div id="hero" className="hero">
      <div className="first"></div>

        <h1 className="expanded">            <a href="https://hoan-my.com/#contact" className="link">hoan-my</a></h1>
        <div className="titles">
          <h2>
            <a href="https://hoan-my.com/#yoga" className="link">YOGA.</a>&nbsp;&nbsp;
            <a href="https://hoan-my.com/#massage" className="link">MASSAGE.</a>&nbsp;&nbsp;
            <a href="https://hoan-my.com/#contact" className="link">BIEN-ÊTRE.</a>
          </h2>

        </div>
        <div className="second"></div>
      </div>
  );
}


