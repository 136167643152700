import React, { Component } from "react";
import "./Massage.css";

export default class Massage extends Component {
  render() {
    return (
      <div id="massage" className="massage">
        <h1>Massage</h1>
        <h2 className="main-heading">
          Laissez-vous transporter dans un monde de détente profonde où chaque séance est conçue spécialement pour vous. 
          Chaque massage est accompagné de mouvements fluides et de pressions ciblées afin de relâcher les tensions musculaires, stimuler la circulation sanguine et favoriser un équilibre énergétique. 
          Cette expérience apaisante et régénératrice permet de retrouver une harmonie dans votre corps. 
          {/* Prenez le temps de vous offrir cette pause bienvenue, où vous pourrez vous reconnecter avec votre essence profonde et revitaliser votre être tout entier. */}
        </h2>
        <div className="row">
          <div className="column">
            <h3>Tui Na</h3>
          </div>
          <div className="column">
            <h3>Moxibustion</h3>
          </div>
          <div className="column">
            <h3>Ventouses</h3>
          </div>
        </div>
        <h2>Réservez un moment privilégié pour vous-même ou pour un être cher.</h2>
        <div id="first" className="first"></div>
      </div>
    );
  }
}
