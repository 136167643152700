import React, { Component } from "react";
import "./Yoga.css";

export default class Yoga extends Component {
  render() {
    return (
      <div id="yoga" className="yoga">
                  <h1>Yoga</h1>
        <h2>
          Plongez dans une expérience douce et enrichissante qui vous permettra d'explorer votre corps et votre esprit. 
           Vous progressez à votre rythme et selon vos besoins tout en bénéficiant de la pleine attention de votre professeure. 
          Peu importe votre niveau de départ, le yoga vous offre une voie vers le bien-être physique et mental.
        </h2>
        <div className="row">
          <div className="column">
            <h3>Pranayama</h3>
          </div>
          <div className="column">
            <h3>Asanas</h3>
          </div>
          <div className="column">
            <h3>Méditation</h3>
          </div>
        </div>
        <h2>Réservez vos cours particuliers ou collectifs.</h2>
        <div id="first" className="first"></div>
      </div>
    );
  }
}
