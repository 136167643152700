import React, { Component } from "react";
import "./Menu.css";

export default class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewportHeight: 0,
            selectedMenu: "hero",
            contentPositions: [],
        };
    }
    componentDidMount() {
        document.addEventListener("scroll", this.onScroll);
        window.addEventListener("resize", this.onResize);
        this.onResize();
    }
    componentWillUnmount() {
        document.removeEventListener("scroll", this.onScroll);
        window.removeEventListener("resize", this.onResize);
    }
    onResize = () => {
        const contentList = document.querySelectorAll(".App > div");
        contentList.forEach((content) => {
            this.state.contentPositions.push({
                id: content.id,
                top: content.offsetTop,
            });
        });
        this.setState({ viewportHeight: window.innerHeight });
    };
    onScroll = () => {
        const scrollValue = window.scrollY + this.state.viewportHeight / 3;
        this.state.contentPositions.forEach((content) => {
            if (scrollValue >= content.top) {
                this.setState({ selectedMenu: content.id });
            }
        });
    };
    render() {
        return (
            <nav className="menu">
                <ul>
                    <li>
                        <a
                            href="#hero"
                            className={
                                this.state.selectedMenu === "hero"
                                    ? "is-active"
                                    : ""
                            }
                        >
                            hoan-my
                        </a>
                    </li>
                    <li>
                        <a
                            href="#yoga"
                            className={
                                this.state.selectedMenu === "yoga"
                                    ? "is-active"
                                    : ""
                            }
                        >
                            yoga
                        </a>
                    </li>
                    <li>
                        <a
                            href="#massage"
                            className={
                                this.state.selectedMenu === "massage"
                                    ? "is-active"
                                    : ""
                            }
                        >
                            massage
                        </a>
                    </li>

                    {/* <li>
                        <a
                            href="#about-me"
                            className={
                                this.state.selectedMenu === "about-me"
                                    ? "is-active"
                                    : ""
                            }
                        >
                            about
                        </a>
                    </li> */}
                    <li>
                        <a
                            href="#contact"
                            className={
                                this.state.selectedMenu === "contact"
                                    ? "is-active"
                                    : ""
                            }
                        >
                            réserver
                        </a>
                    </li>
                </ul>
            </nav>
        );
    }
}
