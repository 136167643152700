import React, { Component } from "react";
import "./Footer.css";
export default class Footer extends Component {
    render() {
        return (
            <div id="footer" className="footer">
                © hoan-my 2020
            </div>
        );
    }
}
