import React, { Component } from "react";
import Hero from "./components/Hero";
import Yoga from "./components/Yoga";
import Massage from "./components/Massage";
import Menu from "./components/Menu";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

import "./App.css";
// import "animate.css/animate.min.css";

class App extends Component {
    render() {
        return (
            <div className="App">
                <Hero />
                <Menu />
                <Yoga />
                <Massage />
                <Contact />
                <Footer />
            </div>
        );
    }
}

export default App;
