import React, { Component } from "react";
import "./Contact.css";
export default class Contact extends Component {
    render() {
        return (
            <div id="contact" className="contact">
<h1>Échangeons pour mieux comprendre vos attentes et vos besoins spécifiques pour vous offrir une expérience sur mesure.</h1>
<div className="row">

</div>
            </div>
        );
    }
}

